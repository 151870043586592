import * as React from 'react'
import {
  VStack,
  Box,
  Text,
  Stack,
  Heading,
  OrderedList,
  ListItem,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react'

// https://gdpr.eu/privacy-notice/

export default function Privacy() {
  return (
    <Stack as={Box} spacing={{ base: 5, md: 8 }}>
      <Heading as="h1" fontSize="3xl">
        Privacy Policy for Flowby AB
      </Heading>
      <Text as="i" style={{ marginTop: 2 }}>Version 1.1, last revised: 2023-01-18</Text>
      <VStack spacing={5} textAlign="left">
        <Text fontSize="xl" color={'gray.700'}>
          When you communicate with us, use our services or become our customer, certain personal data about you will be stored by us. Below you can learn about how we collect and process your information. If you have any questions feel free to contact us anytime at hello@flowby.io.
        </Text>
        <Heading w="100%" as="h2" fontSize="2xl">How we collect personal data</Heading>
        <Text fontSize="xl" color={'gray.700'}>
          We collect personal data using text based input from users of our services as well as via email, phone and other
          communication methods. We use cookies, beacons, tags, scripts and similar tracking technologies to track the activity
          on our service and store certain information. We collect and track information to improve and analyze our service.
        </Text>
        <Heading w="100%" as="h2" fontSize="2xl">How we process personal data</Heading>
        <Text fontSize="xl" color={'gray.700'}>
          We process personal data in order to provide our services to you. In this section, we list when and how we collect the data, what data we collect and the purpose of processing the data.
          We also list the third party processors we share data with.
        </Text>

        <Text fontSize="xl" color={'gray.700'} w="100%">We define three target groups of our product. A single user can be in one or several of these groups.</Text>
        <OrderedList w="100%" pl={{ base: 4, md: 20 }} spacing={4}>
          <ListItem>
            <Text fontSize="xl" color={'gray.700'}>
              Website visitors. <br />
              Visitors to our marketing website, flowby.io.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="xl" color={'gray.700'}>
              Customers. <br />
              Employees or representatives of organizations that have signed up for our product as trial or paying customers, or of companies we think might have an interest in our product.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="xl" color={'gray.700'}>
              End users. <br />
              The customers of our customers, encountering our product as part of our customers' in-store experience.
            </Text>
          </ListItem>
        </OrderedList>

        <Heading w="100%" as="h3" fontSize="xl">Website visitors</Heading>
        <TableContainer w="120%">
          <Table variant='simple' size="sm">
            <Thead>
              <Tr>
                <Th>How</Th>
                <Th>What</Th>
                <Th>Why</Th>
                <Th>Subprocessors</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Using contact form</Td>
                <Td>Name, Email</Td>
                <Td>To be able to respond to your inquiry.</Td>
                <Td>Formspree</Td>
              </Tr>
              <Tr>
                <Td>Opt in to tracking</Td>
                <Td>Cookie ID</Td>
                <Td>Track site usage to improve our product.</Td>
                <Td>Posthog</Td>
              </Tr>
              <Tr>
                <Td>Opt in to tracking</Td>
                <Td>Cookie ID, IP Address</Td>
                <Td>Identify your company to aid in our sales process.</Td>
                <Td>Leadfeeder</Td>
              </Tr>
              <Tr>
                <Td>Opt in to tracking</Td>
                <Td>Cookie ID, IP Address</Td>
                <Td>Identify your linkedin profile for retarget marketing.</Td>
                <Td>Linkedin</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <Heading w="100%" as="h3" fontSize="xl">Customers</Heading>
        <TableContainer w="120%">
          <Table variant='simple' size="sm">
            <Thead>
              <Tr>
                <Th>How</Th>
                <Th>What</Th>
                <Th>Why</Th>
                <Th>Subprocessors</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Starting sales process</Td>
                <Td>Email, Name</Td>
                <Td>Contact info used in sales process.</Td>
                <Td>Pipedrive</Td>
              </Tr>
              <Tr>
                <Td>Signing up</Td>
                <Td>Email</Td>
                <Td>To create your user account.</Td>
                <Td>Google Cloud</Td>
              </Tr>
              <Tr>
                <Td>Login to admin</Td>
                <Td>Cookie ID, Email</Td>
                <Td>Track site usage to improve our product.</Td>
                <Td>Posthog</Td>
              </Tr>
              <Tr>
                <Td>Login to admin</Td>
                <Td>Cookie ID, Email, IP Address</Td>
                <Td>Error tracking to alert us if you have problems.</Td>
                <Td>Rollbar</Td>
              </Tr>
              <Tr>
                <Td>Paying for the product</Td>
                <Td>Email, Name</Td>
                <Td>Contact info used in invoices.</Td>
                <Td>Bokio</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <Heading w="100%" as="h3" fontSize="xl">End users</Heading>
        <TableContainer w="100%" pb={4}>
          <Table variant='simple' size="sm">
            <Thead>
              <Tr>
                <Th>How</Th>
                <Th>What</Th>
                <Th>Why</Th>
                <Th>Subprocessors</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Opt in to SMS notification</Td>
                <Td>Phone Number</Td>
                <Td>To be able to send notification to end user.</Td>
                <Td>Messagebird, Google Cloud</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <Heading w="100%" as="h2" fontSize="2xl">
          Retention of personal data
        </Heading>

        <Text w="100%" fontSize="xl" color={'gray.700'}>
          We will retain your personal data only for as long as is necessary for the purposes set out in this
          Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal
          obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
          disputes, and enforce our legal agreements and policies. We will also retain usage data for internal analysis
          purposes.
        </Text>

        <Heading w="100%" as="h2" fontSize="2xl">
          Transfer of personal data
        </Heading>
        <Text w="100%" fontSize="xl" color={'gray.700'}>
          Your personal data may be transferred to — and maintained on — computers located outside of your state,
          province, country or other governmental jurisdiction where the data protection laws may differ from those
          in your jurisdiction. Your consent to this Privacy Policy followed by your submission of such information
          represents your agreement to that transfer. We will take all steps reasonably necessary to ensure
          that your data is treated securely and in accordance with this Privacy Policy and no transfer of your personal
          data will take place to an organization or a country unless there are adequate controls in place including the
          security of your data and other personal information.
        </Text>

        <Heading w="100%" as="h2" fontSize="2xl">Your data protection rights</Heading>
        <Text fontSize="xl" color={'gray.700'}>
          We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
        </Text>
        <OrderedList w="100%" pl={{ base: 4, md: 20 }} spacing={4}>
          <ListItem>
            <Text fontSize="xl" color={'gray.700'}>
              Right to information. <br />
              You can ask a company for information about what personal data is being processed and why it's processed.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="xl" color={'gray.700'}>
              Right to access. <br />
              You can request a company to provide you with all the personal data they have about you.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="xl" color={'gray.700'}>
              Right to rectification. <br />
              You have the right to request a company to modify your personal data in case it's incorrect or not up to date.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="xl" color={'gray.700'}>
              Right to withdraw consent. <br />
              You have the right to withdraw your consent for data processing at any time.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="xl" color={'gray.700'}>
              Right to object. <br />
              You have the right to object against processing of your personal data.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="xl" color={'gray.700'}>
              Right to object to automated processing. <br />
              You have the right to object to a decision based on automated processing.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="xl" color={'gray.700'}>
              Right to be forgotten. <br />
              You have the right to request a company to delete all personal data they keep about you.
            </Text>
          </ListItem>
          <ListItem>
            <Text fontSize="xl" color={'gray.700'}>
              Data portability. <br />
              You have the right to request a company to provide you with your personal information in a digital format so that you can transfer it to another company/controller.
            </Text>
          </ListItem>
        </OrderedList>

        <Heading w="100%" as="h2" fontSize="2xl">
          Links to other websites
        </Heading>
        <Text w="100%" fontSize="xl" color={'gray.700'}>
          Our website and services contain links to other websites. Our Privacy Policy applies only to our website and services, so if you click on a link to another website, you should read their privacy policy.
        </Text>

        <Heading w="100%" as="h2" fontSize="2xl">
          Changes to our privacy policy
        </Heading>

        <Text w="100%" fontSize="xl" color={'gray.700'}>
          Any changes we may make to our privacy policy in the future will be posted on this page and, when appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy policy.
        </Text>
        <br />
        <Text w="100%" fontSize="xl" color={'gray.700'}>
          Flowby AB <br />
          Bältgatan 6 <br />
          114 59 Stockholm <br />
          Sweden <br />
          Organizational number: 559262-5866 <br />
          Data Protection Officer: Susanne Tedsjö <br />
          Phone: +46 8519 424 09 <br />
          hello@flowby.io <br />
          https://flowby.io
        </Text>
      </VStack>
    </Stack>
  )
}