import * as React from 'react'
import { Container } from '@chakra-ui/react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Navbar from '../components/navbar'
import { Translation } from '../types/main'
import { translations } from '../translations'
import Privacy from '../components/privacy'
import Footer from '../components/footer'

const t: Translation = {
  language: 'en',
  translations: translations.en,
  singleLanguage: true
}

export default function PrivacyPolicy() {
  return (
    <Layout>
      <Seo title="Flowby - Cut your next line" />
      <Navbar t={t} />
      <Container maxW="5xl" py={{ base: 12, md: 24 }}>
        <Privacy />
      </Container>
      <Container maxW="8xl" py={12}>
        <Footer t={t} />
      </Container>
    </Layout>
  )
}
